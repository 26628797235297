<template>
  <div class="record-page">
    <Header :title="'推荐奖励'" :subTitleColor="'#fff'" :subTitleBgColor="'#000'" :bgColor="'rgb(20, 21, 30)'" />
    <div class="recordList">
      <van-list ref="list" v-model="loading" :immediate-check="false" :finished="finished"
        :finished-text="$t('tip.noMore')" @load="onReachBottom">
        <div class="item" v-for="(item, index) in recordList" :key="index">
          <div class="amount">{{ $t('invite.withdrawal') }} {{ item.amount }} USDT</div>
          <div class="address">{{ $t('invite.receivingAddress') }}：{{ item.address.slice(0, 7) }}....{{
            item.address.slice(item.address.length - 7,
              item.address.length) }}</div>
          <div class="tx" v-if="item.status === 20" @click="jumpTo(item.txId)">{{ $t('invite.transactionID') }}：{{
            item.txId.slice(0, 7) }}....{{
    item.txId.slice(item.txId.length - 7,
      item.txId.length) }}</div>
          <div class="right">
            <img v-if="item.status === 20" class="status" src="@/assets/img/user/approved.png" alt="">
            <img v-if="item.status === 30" class="status" src="@/assets/img/user/reviewFailed.png" alt="">
            <img v-if="item.status === 10" class="status" src="@/assets/img/user/waitApprove.png" alt="">
            <div class="date">{{ $moment(item.createdAt).format('MM-DD HH:mm') }}</div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Header from '@/components/Header.vue'
import { queryWithdrawList } from '@/service/user'

export default {
  components: { Header },
  data() {
    return {
      loading: false,
      finished: false,
      pageSize: 5,
      pageNum: 1,
      total: 0,
      recordList: []
    }
  },
  computed: {
  },
  methods: {
    async init() {
      this.getRecordList();
    },
    async onReachBottom() {
      console.log('onReachBottom ....')
      if (this.pageNum * this.pageSize < this.total) {
        this.pageNum++
      } else {
        this.finished = true;
        return
      }
      this.getRecordList()
    },
    async getRecordList() {
      const params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      this.loading = true
      const resp = await queryWithdrawList(params)
      this.loading = false
      if (resp.code === 200) {
        this.total = resp.data.total
        if (this.pageNum === 1) {
          this.recordList = resp.data.rows
        } else {
          this.recordList.push(...resp.data.rows)
        }
        this.finished = this.pageNum * this.pageSize >= this.total;
      }
    },
    jumpTo(tx) {
      window.location.href = `https://bscscan.com/tx/${tx}`;
    }
  },
  mounted() {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
.record-page {
  font-size: 12px;
  background: rgb(20, 21, 30);
  padding-top: 50px;
  display: flex;
  flex-direction: column;

  .recordList {
    padding: 0 20px;

    .item {
      position: relative;
      padding: 20px 0;
      border-bottom: 1px solid rgba(29, 30, 43, 1);

      .amount {
        color: rgba(255, 215, 74, 1);
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .address,
      .tx {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }

      .tx {
        margin-top: 15px;
        color: #fd2084;
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: right;
        width: 80px;
        position: absolute;
        right: 0;
        bottom: 20px;
        align-items: flex-end;

        .status {
          width: 50px;
          margin-bottom: 5px;
        }

        .date {
          font-size: 14px;
          font-weight: 400px;
          color: rgba(255, 255, 255, .7)
        }
      }
    }
  }

}
</style>
